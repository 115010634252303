import "./App.css";
import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { Suspense, useState } from "react";
import Loading from "./components/Loading";
import Heart from './components/Heart';
import AorticAneurysm from './components/AorticAneurysm';
import Caffeine from './components/Caffeine';

const models = {
  Heart: {component: <Heart />, attribution: "Human Heart model by @zames1992"},
  AorticAneurysm: {component: <AorticAneurysm />, attribution: "Abdominal aortic aneurysm model by @laurenwahl"},
  Caffeine: {component: <Caffeine />, attribution: "Caffeine molecule model by Paul Bourke"},
  Loader: {component: <Loading />, attribution: ""}
}

const keys = Object.keys(models);

function App() {
  const [model, setModel] = useState("Heart");

  return (
    <div className="App">
      {/* <header className="App-header"><strong>RAMAGE</strong></header> */}
      <div className="CanvasWrapper">
        {/* <TextScroller /> */}
      <div className="OverlayFooter">
      {/* <div className="Attribution">{models[model].attribution}</div> */}
      {/* <div className="Attribution">Background: "Hospital Room" by Oliksiy Yakovlyev</div> */}
      <div className="Controls">
        <div className="ControlKey">
          Change Model: 
        </div>
        <div>
          {keys.map((m) => <button onClick={() => setModel(m)} key={m}>{m}</button>)}
        </div>  
      </div>
      </div>
      <Canvas className="PrimaryCanvas">
        <Suspense fallback={<Loading />}>
        <pointLight position={[10, 10, 10]} />
          {models[model].component}
          <OrbitControls enableZoom={false} />
          <Environment files={"hospital_room_1k_light.hdr"} background />
        </Suspense>
      </Canvas>
      </div>
    </div>
  );
}

export default App;
