import Model from "./Model";
import Loading from "./Loading";
import {Suspense} from 'react';

const Caffeine = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Model
        modelPath={"caffeine_molecule/scene.gltf"}
        scale={2}
        position={[0,0,0]}
      />
    </Suspense>
  );
};

export default Caffeine;