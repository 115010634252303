import { Text } from "@react-three/drei";
const Loading = () => (
  <mesh>
    <Text color="#61dafb" fontSize={0.5} position={[0,0,3]}>
      Loading...
    </Text>
    <Text color="#61dafb" fontSize={0.5} position={[0,0,-3]} rotation={[0,Math.PI,0]}>
      Loading...
    </Text>
    <sphereGeometry args={[2.5, 32, 32]} />
    <meshStandardMaterial  metalness={1} roughness={0}/>
  </mesh>
);

export default Loading;