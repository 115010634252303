import Model from "./Model";
import Loading from "./Loading";
import {Suspense} from 'react';

const Heart = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Model
        modelPath={"human_heart/scene.gltf"}
        scale={0.5}
        position={[0,-1.5,0]}
      />
    </Suspense>
  );
};

export default Heart;
