import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const Model = props => {
  const {modelPath, ...rest} = props;
  const gltf = useLoader(GLTFLoader, modelPath);
  return (
    <>
      <primitive object={gltf.scene} {...rest} />
    </>
  );
};

export default Model;