import Model from "./Model";
import Loading from "./Loading";
import {Suspense} from 'react';

const AorticAneurysm = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Model
        modelPath={"abdominal_aortic_aneurysm/scene.gltf"}
        scale={1.5}
        position={[0,-2,0]}
      />
    </Suspense>
  );
};

export default AorticAneurysm;
